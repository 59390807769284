(function ($) {
  var CARD_SLICK_OPTIONS = {
    dots: false,
    infinite: true,
    nextArrow: '<a class="btn btn-link btn-next"><i class="fa fa-caret-right"></i></a>',
    prevArrow: '<a class="btn btn-link btn-prev"><i class="fa fa-caret-left"></i></a>',
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 574,
        settings: "unslick"
      }
    ]
  };

  var Selzer = {
    'common': {},
    'home': {},
    'single_team': {
      init: function () {
        $('.properties-carousel').slick(CARD_SLICK_OPTIONS);
      },
      resize: function () {
        $('.properties-carousel').slick(CARD_SLICK_OPTIONS);
      }
    }
  };

  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Selzer;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    },
    resizeEvents: function () {
      UTIL.fire('common', 'resize');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm, 'resize');
      });
    }
  };
  $(document).ready(UTIL.loadEvents);

  $(window).resize(UTIL.resizeEvents);

})(jQuery);
